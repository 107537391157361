<template>
  <div class="relative w-full" :class="{ 'mt-10': containImages, 'mt-5': containVerticalImages }">
    <Swiper
      :modules="modules"
      :slides-per-view="auto ? 'auto' : 3.5"
      :space-between="10"
      :slides-offset-before="0"
      :center-insufficient-slides="true"
      :breakpoints="{
        320: {
          slidesPerView: 'auto',
          spaceBetween: 15,
          ...props.midScreenAttributes,
        },
        1024: {
          slidesPerView: 'auto',
          spaceBetween: 16,
          slidesOffsetBefore: 0,
          ...largeScreenAttributes,
        },
      }"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      class="swiper-container"
    >
      <SwiperSlide v-for="(item, index) in items" :key="index" class="swiper-slide">
        <slot :item="{ ...item, index }" />
      </SwiperSlide>
      <SwiperControls :current-index="currentIndex" @change="(index: number) => (currentIndex = index)" />
    </Swiper>

    <!-- If we need navigation buttons -->
    <button class="swiper-nav swiper-button-prev hidden lg:block" aria-label="previous slide" @click="prevSlide">
      <svg-icon-chevron-right class="w-10 h-10" :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']" />
    </button>
    <button
      class="swiper-nav swiper-button-next rounded-full hidden lg:block"
      aria-label="next slide"
      @click="nextSlide"
    >
      <svg-icon-chevron-right class="w-10 h-10" :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

const modules = [Navigation, Autoplay];
defineComponent({
  name: 'BulletsSlider',
});
const props = defineProps({
  items: {
    type: Array as PropType<any[]>,
    required: true,
  },
  slidesPerViewMobile: {
    type: Number,
    default: 2.5,
  },
  containImages: {
    type: Boolean,
    default: false,
  },
  containVerticalImages: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'primary',
  },
  selectedItem: {
    type: Object,
    default: null,
  },
  auto: {
    type: Boolean,
    default: false,
  },
  mobileScreenAttributes: {
    type: Object,
    default: () => ({}),
  },
  midScreenAttributes: { type: Object, default: () => ({}) },

  largeScreenAttributes: { type: Object, default: () => ({}) },
  arrows: {
    type: Boolean,
    default: false,
  },
});

const currentIndex = ref(0);

function nextSlide() {
  currentIndex.value = getValidValue(currentIndex.value + 1);
}

function prevSlide() {
  currentIndex.value = getValidValue(currentIndex.value - 1);
}

function getValidValue(num: number) {
  return clamp(0, props.items.length - 1, num);
}
</script>

<style lang="postcss" scoped>
.swiper-container {
  @apply static;
  width: 100%;

  &.has-margin-left {
    @apply ml-64;
  }
}

.swiper-slide {
  width: auto;
}

.swiper-nav {
  width: 35px;
  height: 35px;

  &::after {
    content: none;
  }

  &.swiper-button-next {
    position: absolute;
    right: -20px;
    top: 9px;
  }

  &.swiper-button-prev {
    position: absolute;
    transform: scaleX(-1);
    left: -20px;
    top: 9px;
  }

  @media (min-width: 1301px) {
    &.swiper-button-next {
      right: -45px;
    }

    &.swiper-button-prev {
      left: -45px;
    }
  }

  &.swiper-button-disabled {
    opacity: 0.5;
  }

  @media (max-width: 1220px) {
    display: none;
  }
}
</style>
